.page-title {
    font-size: 20px;
    line-height: 27px;
    color: #152744;
    border-bottom: 1px solid #E3E9F7;
    padding-bottom: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}