.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 950px;
    margin: auto;
}

.knowledge-center-card {
    padding: 4px;
    border-radius: 2px;
    width: 12rem;
    height: 15rem;
    background-color: rgba(229, 229, 229, 0.9);
    margin: 1rem;

    .title {
        text-align: center;
        font-weight: bold;
        font-size: small;
        color: #0E1C33;
    }

    .icon {
        text-align: center;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
    }

    .button {
        text-align: center;
    }
}
