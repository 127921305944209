

body.onboarding-page,
body.signin,
body.signup {
  font-family: 'Open Sans';

  nav {
    display: none;
  }
  .nav-bar {
    display: none;
  }
  footer {
    display: none;
  }
  .stepper {
    display: none;
  }
  .main {
    min-height: 0;
    padding: 0;
  }
  .right-of-nav-bar {
    margin-left: 0;
  }
  form {
    max-width: 324px;
    margin-left: auto;
    margin-right: auto;
  }
  .content-container {
    background-color: white;

    .content {
      padding: 0;
      margin-left: 0;
      margin-right: none;
      max-width: none;
    }
  }

  .left {
    background-color: #2779fb;
    padding-left: 80px;
    padding-top: 10px;
    min-height: 100vh;
  }

  .right {
    padding-bottom: 25%;
    background-color: #ffffff;
  }

  .safe-lease-logo {
    height: 120px;
    width: 200px;
  }

  .onboarding-container {
    h1 {
      height: 100px;
      color: #ffffff;
      font-size: 60px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 74px;
    }

    h3 {
      color: #ffffff;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      width: 560px;
    }

    .onboarding-description {
      height: 85px;
    }

    .onboarding-locations-header {
      margin-bottom: 20px;
    }
  }
  .help-text {
    height: 24px;
    width: 300px;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 50px;
    padding-bottom: 15px;
  }
  .locations-list-item {
    height: 36px;
    width: 400px;
    border-radius: 17px;
    border: 0px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    background-color: rgba(15, 40, 70, 0.2);
    color: #ffffff;
    
    Button {
      min-width: fit-content;
    }
  }
  .locations-list-item:hover {
    background-color: #4b4b4b;
  }
  .locations-list-item-highlighted {
    height: 40px;
    width: 400px;
    border-radius: 17px;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 8px #353535;
    color: #031e30;
    
    Button {
      min-width: fit-content;
    }
  }
  .back-to-app-header {
    display: flex;
    padding-right: 60px;
    justify-content: flex-end;
  }
  .back-to-app-button {
    min-width: 0;
    color: #031e30;;
  }
  .plan-details-button {
    min-width: 0;
    color: #031e30;
    margin-top: 10px;
  }
  .learn-more-link {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.15px;
  }
  .locations-list-paper {
    max-height: 250px;
    overflow: auto;
    background-color: inherit;
    box-shadow: none;
  }
  .form-paper {
    max-height: 70vh;
    overflow: auto;
    background-color: inherit;
    box-shadow: none;
  }

  .protection-levels-list-paper {
    max-height: 370px;
    background-color: inherit;
    box-shadow: none;
  }

  .accept-invitation {
    .help-text {
      display: none;
    }
    .list-group,
    .learn-more-link {
      display: none;
    }

    form {
      margin-top: 170px;

      .input {
        padding-left: 80px;

        fieldset {
          box-sizing: border-box;
          height: 58px;
          width: 328px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
        }

        input,
        select {
          min-width: 300px;
          max-width: 300px;
        }
      }
    }

    .MuiButton-root {
      margin-left: 0;
      float: right;
      top: 125px;
      background-color: #e9645f;
    }

    .MuiButton-root:hover {
      background-color: #eb504a;
    }

    .MuiButton-root:active {
      background-color: #d83f39;
    }
  }
  .MuiFormLabel-root {
    height: 18px;
    width: 268px;
    color: #152744;
    font-size: 14px;
    line-height: 20px;
  }

  .policy-text {
    margin-top: 20px;
    font-size: 12px;
  }

  .onboarding-split-layout {
    .right {
      padding-top: 40px;
    }

    p {
      color: #152744;
      font-size: 14px;
      font-weight: bold;
    }

    .add-location-textfield {
      width: 324px;
      margin-bottom: 20px;
    }

    .add-location-textfield-no-bottom {
      width: 324px;
    }
    .form-header {
      display: flex;
      justify-content: space-between;

      Button {
        padding-top: 0;
        margin-left: 10px;
        margin-bottom: 10px;
      }
    }

    .MuiFormHelperText-root {
      color: #e9645f;
      font-size: 12px;
      padding-left: 0px;
    }

    .cancel-button {
      margin-top: 30px;
      color: black;
      font-size: 14px;
      font-weight: bold;
    }
    .cancel-button:hover {
      color: #2779fb;
    }
    .return-button {
      margin-top: 30px;
      color: black;
      font-size: 14px;
      font-weight: bold;
    }
    .return-button:hover {
      color: #2779fb;
    }
    .next-button {
      float: right;
      margin-top: 30px;
      background-color: #e9645f;
      width: 104px;
      color: #ffffff;
      font-weight: bold;
    }
    .next-button:hover {
      background-color: #eb504a;
    }

    .setup-footer {
      width: 90%;
      margin: auto;
    }
    .setup-footer-buttons {
      display: flex;
    }

    form {
      .input {
        padding-left: 80px;

        fieldset {
          box-sizing: border-box;
          height: 58px;
          width: 328px;
          border: 1px solid #e0e0e0;
          border-radius: 4px;
        }
      }

      .small-input-field {
        input,
        select {
          min-width: 120px;
        }
      }

      .entity-billing-grid {
        margin-top: 1px;
      }

      .billing-info-text {
        padding-top: 10px;
      }
      .llc-page-divider {
        margin-top: 50px;
      }
      input,
      select {
        min-width: 150px;
        max-width: 300px;
      }

      .MuiGrid-root {
        width: 350px;

        .input {
          fieldset {
            width: 150px;
          }
        }
      }

      .split-input-fields {
        width: 340px;
        
        input,
        select {
          min-width: 120px;
        }
      }

      .MuiLinearProgress-root {
        margin-top: 20px;
      }

      .fms-choices {
        margin-top: 20px;
        margin-bottom: 20px; 
        width: 100%;

        .Mui-disabled:hover {
          cursor: pointer;
          .MuiSelect-select {
            background-color: #f5f5f5;
          }
          .MuiOutlinedInput-notchedOutline {
            border-color: #152744;
          }
        }
      }

      .fms-choices-button {
        box-sizing: border-box;
        height: 64px;
        width: 152px;
        border: 1px solid #bdbdbd;
        border-radius: 4px;
        background-color: #ffffff;
        margin-left: 0;
        margin-right: 24px;
        font-size: 12px;
        font-weight: bold;
        .badge {
          display: none;
        }
      }

      .active-fms-button {
        box-sizing: border-box;
        height: 64px;
        width: 152px;
        border: 1px solid #2879fb;
        border-radius: 4px;
        background-color: #0f2846;
        margin-left: 0;
        margin-right: 24px;
        font-size: 12px;
        font-weight: bold;
        color: white;
      }

      .button-run-setup {
        margin-left: 80px;
        margin-bottom: 10px;
        box-sizing: border-box;
        height: 36px;
        border: 1px solid #152744;
        border-radius: 17px;
        color: #152744;

        &:disabled {
          color: #bdbdbd;
          border-color: #bdbdbd;
        }
      }

      // Autopay agreement text
      .MuiTypography-body1 {
        height: 20px;
        width: 290px;
        color: #152744;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.21px;
        line-height: 20px;
      }

      .autopay-checkbox {
        padding-left: 80px;
        padding-bottom: 50px;
      }
    }
  }

  .modal-confirm {
    .buttons {
      float: right;
    }
    h4 {
      padding-top: 15px;
      color: #0f2846;
      letter-spacing: 0.15px;
      text-align: center;
      margin-bottom: 30px;
    }
    h6 {
      color: #0f2846;
      font-size: 14px;
      letter-spacing: 0.15px;
      text-align: center;
      font-weight: 600;
    }
    .no-confirm-button {
      color: black;
      background-color: #ffffff;
      width: 104px;
      border: 1px solid black;
    }
    .yes-confirm-button {
      background-color: #0f2846;
      float: right;
      width: 104px;
    }
  }

  .modal-select {
    display: flex;
    justify-content: center;
    margin-top: 5px;

    .MuiFormControl-root {
      width: 280px;
      background-color: #ebeff7;
    }
    .MuiList-root {
      background-color: #ebeff7;
    }
  }
  .modal-credentials-toggle {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .MuiFormControlLabel-root {
      margin-left: 0px;
    }
  }

  .add-llc-page {
    form {
      .MuiButton-contained {
        float: right;
        top: 100px;
        background-color: #e9645f;
        width: 104px;
      }
    }
  }


  .account-summary-page {
    .right {
      // padding-top: 150px;
      padding-bottom: 40%;
    }
    .account-summary-header {
      color: #152744;
      font-size: 30px;
      font-weight: 600;
      padding-left: 50px;
      padding-bottom: 50px;
      height: 43px;
    }
    .account-summary-table {
      box-shadow: none;
    }
    .account-summary-theadCell {
      font-family: 'Open Sans';
      font-size: 14px;
    }
    .account-summary-tbodyCell {
      font-family: 'Open Sans';
      font-size: 14px;
      Button {
        font-size: 12px;
        text-transform: inherit;
      }
    }
    h6 {
      font-size: 14px;
      line-height: 20px;
      height: 15px;
      padding-left: 20px;
    }
    .hide-label {
      .MuiFormLabel-root {
        display: none;
      }
    }
  }
  .account-migration {
    .migration-form {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px;
    }
    .MuiFormControlLabel-label {
      font-size: 15px;
      font-weight: bold;
    }
    .account-migration-grid {
      width: 500px;
      padding-left: 20px;
    }
    .account-migration-form {
      width: 200px;
      left: 20px;
    }
    .account-migration-checkbox {
      margin-left: 0px;
      padding-bottom: 15px;
    }
    .multi-unit-checkbox {
      padding-left: 10px;
    }
    .checkbox-div {
      padding-left: 20px;
    }
    h5 {
      padding-bottom: 20px;
    }
    .account-migration-text {
      width: 300px;
      padding-left: 20px;
      padding-bottom: 60px;
      font-weight: 100;
    }
    .account-migration-title {
      font-size: 15px;
      font-weight: bold;
      padding-left: 0px;
      padding-top: 5px;
    }
    .account-migration-label {
      font-family: 'Open Sans';
    }
    .account-migration-date {
      padding-left: 20px;
      padding-bottom: 15px;
    }
    .Mui-error {
      border-color: rgba(0, 0, 0, 0.23);

      fieldset {
        border-color: rgba(0, 0, 0, 0.23);
      }
    }
    .MuiFormLabel-root {
      font-size: 16px;
    }
    .account-migration-grids {
      padding-bottom: 50px;
    }
    .account-migration-date-picker {
      top: 20px;
    }
    .MuiLinearProgress-root {
      margin-top: 20px;
    }
    .button-add-location {
      margin-bottom: 10px;
      box-sizing: border-box;
      height: 36px;
      width: 180px;
      border: 1px solid #152744;
      border-radius: 17px;
      color: #152744;
    }
    .info-tooltip {
      padding: 0px;
      margin: 0px;
      justify-content: flex-start;
      min-width: 0px;
    }
    .info-tooltip:hover {
      background-color: #ffffff;
    }

    .account-migration-sitelink-select {
      margin-right: 10px;
    }
    .account-migration-mapping-button {
      top: 19px;
      right: 95px;
    }
    .account-migration-page-divider {
      margin-top: 25px;
      margin-bottom: 25px;
    }
  }
  .mapping-modal-title {
    color: black;
    height: 35px;
    text-align: center;
  }

  .plan-details {
    .right {
      padding-bottom: 25%;
      padding-top: 40px;
    }
    .protection-level-title {
      padding-left: 30px;
      width: 138px;
      font-size: 12px;
      color: #6e82a9;
      display: inline-block;
    }
    .plan-detail-addnew-button {
      left: 80px;
      margin-bottom: 10px;
    }
  }
  .letter-preview {
    .right {
      padding-top: 120px;
    }
    .list-group {
      display: none;
    }
    .letter-preview-card {
      height: 700px;
      min-width: 275;
      margin-top: 20px;
      background-color: #eeeeee;
    }
    .reminder {
      height: 400px;
    }
    p {
      display: inline-block;
      padding-left: 30px;
      padding-right: 20px;
    }
    h2 {
      padding-bottom: 10px;
    }
    h4 {
      font-size: 15px;
      font-weight: bold;
    }
    h5 {
      font-size: 15px;
    }
    ul {
      padding-left: 50px;
      font-size: 14px;
    }
    .letter-subject {
      padding-left: 20px;
      padding-top: 10px;
      font-size: 17px;
      font-weight: bold;
    }
  }
  .signin-page {
    .right {
      padding-bottom: 15%;
    }
    .left {
      position: relative;
      overflow: hidden;
      .bg-img-1 {
        position: absolute;
        top: 0px;
        left: -70px;
      }
      .bg-img-2 {
        position: absolute;
        bottom: -50px;
        right: -50px;
      }
    }
    .safelease-logo {
      padding-left: 20px;
    }
    form {
      margin-left: 0;
    }
    .policy-text {
      margin-left: 0;
    }
  }
  .signup-page {
    .right {
      padding-bottom: 10px;
    }
    .description {
      font-size: 16px;
      padding-left: 110px;
      padding-top: 20px;
    }
  }
}


body.onboarding-page {
  .protection-levels {
    margin-top: 20px;

    .input-grid {
      padding-bottom: 20px;
      padding-left: none;
    }

    p {
      padding-left: 0;
    }
    .protection-level-title {
      padding-left: 0px;
      width: 138px;
      font-size: 12px;
      color: #6e82a9;
      display: inline-block;
    }
    .premium {
      margin-left: 30px;
    }
  }

  .MuiIconButton-root {
    margin-left: 20px;
  }
}
