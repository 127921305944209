.otp-enroll {
  .instructions, .code {
    margin-bottom: 20px;
  }

  .code {
    background-color: white;
    width: 304px;
    svg {
      margin: 24px;
    }
  }
}

.otp-validate {
  .instructions {
    margin-bottom: 20px;
  }
}

.view-credentials {
  .instructions {
    margin-bottom: 20px;
  }

  table {
    td {
      padding: 5px;
    }
  }
}

.notes {
  margin-bottom: 50px;
}
