.photo-picker {
  width: 275px;
  height: 150px;
  display: flex;

  &--main-photo {
    height: 150px;
    width: 150px;
    background-color: #EEEEEE;
    margin-right: 15px;
    overflow: hidden;
  }

  &--list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 15px;

    &--thumbnail {
      height: 40px;
      width: 40px;
      background-color: #EEEEEE;
      overflow: hidden;

      &__overlay {
        // TODO
        background-color: rgba(0,0,0,0.5);
      }
    }
  }

  &--image {
    max-height: 100%; 
    max-width: 100%; 
    object-fit: cover;
    min-height: 100%; 
    min-width: 100%; 
    &:hover {
      cursor: pointer;
    }
  }

  &--modal-image {
    object-fit: contain;
    max-width: 90%;
    max-height: 90%;
    &:focus {
      outline: none;
    }
  }

  &--x {
    position: relative;
    color: rgba(255,255,255,0.3);
    font-size: 50px;
    cursor: pointer;

    &:hover {
      color: rgba(255,255,255,0.6);
    }
  }
}
