fieldset {
  legend {
    width: unset;
  }
}



body {
  overscroll-behavior: none;
  nav {
    max-width: 1250px;
    padding: 32px 20px;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    align-items: center;

    .home {
      margin-right: 25px;
    }
    ul {
      font-size: 15px;
      font-weight: 700;
      margin: 0;

      li {
        display: inline-block;
        padding: 0 22px;
        a, button {
          text-decoration: none;
          font-weight: bold;
          border: none;
          background: none;
          color: #0d6efd;
        }
      }
    }
  }

  .content-container {
    background-color: white;
    flex-grow: 1;
    min-height: 93vh;
    .content {
      min-height: 93vh;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }


  footer {
    padding: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
  }

  form {

    .description {
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 12px;
    }

    .input {
      input, select {
        min-width: 400px;
      }
    }

    .input + .input {
      margin-top: 20px;
    }


    .error {
      color: red;
    }

    .buttons {
      margin-top: 20px;
      text-align: center;

      button {
        .busy-indicator {
          display: none;
          margin-right: 5px;
        }
      }
    }
  }

  form.busy {
    .busy-indicator {
      display: inline !important;
    }
  }
}

.signin {
  .forgot-password {
    text-align: center;
    margin-top: 10px;
    font-weight: bold;
    font-size: 12px;
  }
}

.MuiTableHead-root {
  tr th {
    font-weight: bold;
  }
}

.MuiButton-root.pagination-more-button {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

body.wizard {
  nav {
    display: none;
  }

  .content-container {
    background-color: white;
    .content {
      max-width: none;
      padding: 0;
    }
  }

  footer {
    display: none;
  }
}


.setup-relationship {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  .stepper {
    padding: 20px;
    background-color: #F0F1F9;
    .logo {
      margin-bottom: 20px;
    }
    .MuiStepper-root {
    }
  }
  .main {
    min-height: 100vh;
    padding: 40px;
    flex-grow: 1;

    h1 {
      margin-bottom: 30px;
    }

    .instructions {
      margin-bottom: 30px;
      font-size: 20px;
    }

    .question {
      padding: 50px;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      .question-title {
        font-size: 30px;
        text-align: center;
        margin-bottom: 30px;
      }

      .question-body {
        margin-bottom: 30px;
      }

      .answers {
        display: flex;
        flex-direction: row;
        justify-content: center;

        button + button {
          margin-left: 20px;
        }

      }

    }

    .question-wide {
      max-width: 1200px;
    }

    .choose-fms-step {

      .choice {
        text-align: center;
        width: 100px;
        height: 125px;
        margin-right: 30px;

        img, svg {
          border: 2px solid #EDEDED;
          border-radius: 10px;
          padding: 5px;
          width: 75px;
          height: 75px;
          margin-bottom: 10px;
        }
        .name {
          text-align: center;
          color:  #2D72E7;
          font-weight: bold;
          font-size: 13px;
        }
      }
    }

    .credentials-step {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
      .credentials-container {
        padding: 40px;
        line-height: 40px;

        .name-and-logo {
          margin-bottom: 20px;
          display: flex;
          .logo {
            width: 70px;
            height: 70px;
            margin-right: 20px;
          }
          .name {
            font-size: 40px;
          }
        }

        .credentials-input {
          width: 100%;
        }

        .credentials-input + .credentials-input {
          margin-top: 10px;
        }
      }
    }

    .location-confirmation-step {
      .variable {
        font-weight: bold;
      }

      .selected-account {
        padding-top: 20px;
        .MuiFormControl-root {
          width: 100%;
        }
      }
    }

    .auto-enroll-step {
      .input {
        margin-top: 20px;
        justify-content: center;
      }
    }

    .rollover {
      .sitelink {
        float: right;
        .strategy-select {
          margin-right: 10px;
        }
      }

      .hint {
        font-size: 11px;
      }

      .disabled > * {
        color: #bdbdbd !important;
      }

      .notes {
        &.hidden {
          display: none;
        }
      }

      .selectAll {
        white-space: nowrap;
      }

      th {
        &.calendar {
          label {
            display: none;
          }
          input {
            visibility: hidden;
            width: 1px;
            padding: 0;
            margin: 0;
          }
          fieldset {
            display: none;
          }
          div {
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .auto-enroll-step {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;


    h2 {
      margin-bottom: 20px;
    }

    .preserve-width {
      width: 200px;
    }

    .days-before, .grace-period {
      width: 100%;
    }

    .buttons {
      text-align: center;
    }
  }
}



.table-input {
  margin-bottom: 30px;
  .table-input-row + .table-input-row {
    margin-top: 25px;
  }

  .table-input-row {
    display: flex;
    .field + .field {
      margin-left: 20px;
    }

    button {
      width: 50px;
    }
  }

  .add {
    margin-top: 20px;
  }
}

.buttons {
  padding-top: 20px;
  .next {
    margin-top: 40px;
  }
}

.admin-billing {
  padding: 50px;

  .element {
    margin-bottom: 20px;
  }
}

.right-of-nav-bar {
  flex: 1 1 auto;
  overflow-x: scroll;
  height: 100vh;
  min-width: 995px; // Total 1180 min width w/ 185 px nav drawer

  &__with-nav {
    max-height: 100vh;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: stretch;
  max-height: 100vh;
}


.locations-page {
   font-family: "Open Sans";
  .content-container {
    background-color: white;
    .content {
      padding: 25px;
      margin-left: 0;
      margin-right: none;
      max-width: none;
    }
    .MuiTableHead-root tr th {
      font-weight: bold;
      color: rgba(21,39,68,0.62);
      font-size: 12px;
    }
    .MuiTableBody-root tr td {
      color: #152744;
      font-size: 13px;
    }
  }
}



.zero-state {
  width: 250px;
  margin: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: #7487AC;

  svg {
    font-size: 100px;
    margin-bottom: 20px;
    color: #CFD5E2;
  }

  .title {
    margin-top: 30px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.terms-modal {
  .title {
    font-size: 30px;
  }

  .body {
    margin: 20px 0;
    max-height: 60vh;
    overflow-y: scroll;

    h3 {
      font-size: 20px;
    }
    
  }

  .buttons {
    text-align: center;
    button + button {
     margin-left: 20px;
    }
  }
}

.popup-modal {
  .modal-box {
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    max-height: 85%;
    width: 450px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 24;
    padding: 32px;
    border-radius: 16px;

    p {
      color: #0f2846;
      font-size: 14px;
      letter-spacing: 0.15px;
      line-height: normal;
      text-align: center;
    }
    .modal-address-text {
      font-weight: bold;
    }
  }
  .modal-logo {
    display: flex;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 10px;
      height: 50px;
    }

    h2 {
      color: #040404;
      font-size: 18px;
      font-weight: bold;
      top: 20px;
      margin-top: 2px;
      text-align: center;
    }
  }
  .modal-fields {
    display: flex;
    justify-content: center;
    align-content: space-around;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
  .modal-input-field {
    width: 280px;
    margin-top: 20px;
  }
  .modal-location-title {
    margin-top: 5px;
  }
  .modal-login-button {
    background-color: #0f2846;
    margin-top: 15px;
    max-width: 200px;
    color: #ffffff;
  }
  .MuiFormHelperText-root {
    color: #e9645f;
    font-size: 12px;
    padding-left: 0px;
    line-height: 8px;
  }
  .info-tooltip {
    padding: 0px;
    margin: 0 0 0 3px;
    min-width: 0px;
    line-height: 0px;
    background-color: #ffffff;
  }
  .info-tooltip:hover {
    background-color: #ffffff;
  }
}

.sort-icon {
  height: 18px;
  width: 18px;
}

.link-renderer {
  color: #2879FB;
}

textarea::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

textarea::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
