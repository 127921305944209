*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.tw-invisible {
  visibility: hidden;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw--top-\[150px\] {
  top: -150px;
}

.tw-bottom-\[10px\] {
  bottom: 10px;
}

.tw--top-\[40px\] {
  top: -40px;
}

.tw-float-right {
  float: right;
}

.tw-m-auto {
  margin: auto;
}

.tw-m-2 {
  margin: 0.5rem;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-mr-2 {
  margin-right: 0.5rem;
}

.tw-mt-52 {
  margin-top: 13rem;
}

.tw-ml-\[50\%\] {
  margin-left: 50%;
}

.tw-mr-\[50\%\] {
  margin-right: 50%;
}

.tw-ml-10 {
  margin-left: 2.5rem;
}

.tw-mb-10 {
  margin-bottom: 2.5rem;
}

.tw-mt-\[-75px\] {
  margin-top: -75px;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw--ml-4 {
  margin-left: -1rem;
}

.tw-mb-0 {
  margin-bottom: 0px;
}

.tw-mr-4 {
  margin-right: 1rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-mb-\[26px\] {
  margin-bottom: 26px;
}

.tw-mb-1 {
  margin-bottom: 0.25rem;
}

.tw-ml-2 {
  margin-left: 0.5rem;
}

.tw-mb-3 {
  margin-bottom: 0.75rem;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-ml-4 {
  margin-left: 1rem;
}

.tw-mt-20 {
  margin-top: 5rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-mt-12 {
  margin-top: 3rem;
}

.tw-mt-2 {
  margin-top: 0.5rem;
}

.tw-ml-3 {
  margin-left: 0.75rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-mt-5 {
  margin-top: 1.25rem;
}

.tw-mb-2\.5 {
  margin-bottom: 0.625rem;
}

.tw-mr-\[17px\] {
  margin-right: 17px;
}

.tw-ml-20 {
  margin-left: 5rem;
}

.tw-ml-12 {
  margin-left: 3rem;
}

.tw-mb-5 {
  margin-bottom: 1.25rem;
}

.tw-mt-10 {
  margin-top: 2.5rem;
}

.tw-ml-\[74px\] {
  margin-left: 74px;
}

.tw-mr-\[10px\] {
  margin-right: 10px;
}

.tw-ml-1 {
  margin-left: 0.25rem;
}

.tw-mb-\[60px\] {
  margin-bottom: 60px;
}

.tw-mt-1 {
  margin-top: 0.25rem;
}

.tw-ml-auto {
  margin-left: auto;
}

.tw-flex {
  display: flex;
}

.tw-grid {
  display: grid;
}

.tw-h-\[9px\] {
  height: 9px;
}

.tw-h-full {
  height: 100%;
}

.tw-h-64 {
  height: 16rem;
}

.tw-h-\[150px\] {
  height: 150px;
}

.tw-h-\[40px\] {
  height: 40px;
}

.tw-h-16 {
  height: 4rem;
}

.tw-h-\[16px\] {
  height: 16px;
}

.tw-h-\[20px\] {
  height: 20px;
}

.tw-h-screen {
  height: 100vh;
}

.tw-h-12 {
  height: 3rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-w-\[9px\] {
  width: 9px;
}

.tw-w-full {
  width: 100%;
}

.tw-w-64 {
  width: 16rem;
}

.tw-w-\[150px\] {
  width: 150px;
}

.tw-w-\[100px\] {
  width: 100px;
}

.tw-w-\[40px\] {
  width: 40px;
}

.tw-w-\[260px\] {
  width: 260px;
}

.tw-w-\[350px\] {
  width: 350px;
}

.tw-w-96 {
  width: 24rem;
}

.tw-w-\[20px\] {
  width: 20px;
}

.tw-w-6\/12 {
  width: 50%;
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-w-1\/3 {
  width: 33.333333%;
}

.tw-w-auto {
  width: auto;
}

.tw-w-1\/4 {
  width: 25%;
}

.tw-w-1\/12 {
  width: 8.333333%;
}

.tw-w-2\/3 {
  width: 66.666667%;
}

.tw-w-1\/2 {
  width: 50%;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-\[20rem\] {
  width: 20rem;
}

.tw-max-w-2xl {
  max-width: 42rem;
}

.tw-max-w-screen-xl {
  max-width: 1280px;
}

.tw-flex-1 {
  flex: 1 1 0%;
}

.tw-flex-auto {
  flex: 1 1 auto;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-flex-row {
  flex-direction: row;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-end {
  align-items: flex-end;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-start {
  justify-content: flex-start;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-around {
  justify-content: space-around;
}

.tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.tw-overflow-y-scroll {
  overflow-y: scroll;
}

.tw-break-words {
  overflow-wrap: break-word;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-3xl {
  border-radius: 1.5rem;
}

.tw-rounded-xl {
  border-radius: 0.75rem;
}

.tw-rounded {
  border-radius: 0.25rem;
}

.tw-rounded-\[4px\] {
  border-radius: 4px;
}

.tw-rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tw-rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.tw-border-0 {
  border-width: 0px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-solid {
  border-style: solid;
}

.tw-border-\[\#E3E9F7\] {
  --tw-border-opacity: 1;
  border-color: rgb(227 233 247 / var(--tw-border-opacity));
}

.tw-border-\[\#CBDFF6\] {
  --tw-border-opacity: 1;
  border-color: rgb(203 223 246 / var(--tw-border-opacity));
}

.tw-bg-\[\#E9645F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(233 100 95 / var(--tw-bg-opacity));
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tw-bg-\[\#152744aa\] {
  background-color: #152744aa;
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.tw-bg-\[\#2879fb\] {
  --tw-bg-opacity: 1;
  background-color: rgb(40 121 251 / var(--tw-bg-opacity));
}

.tw-bg-\[\#ec504a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(236 80 74 / var(--tw-bg-opacity));
}

.tw-bg-\[\#152744\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 39 68 / var(--tw-bg-opacity));
}

.tw-bg-cover {
  background-size: cover;
}

.tw-p-6 {
  padding: 1.5rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-20 {
  padding: 5rem;
}

.tw-p-1 {
  padding: 0.25rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-pb-2 {
  padding-bottom: 0.5rem;
}

.tw-pb-\[17px\] {
  padding-bottom: 17px;
}

.tw-pb-3 {
  padding-bottom: 0.75rem;
}

.tw-pl-10 {
  padding-left: 2.5rem;
}

.tw-pr-10 {
  padding-right: 2.5rem;
}

.tw-pl-5 {
  padding-left: 1.25rem;
}

.tw-pr-5 {
  padding-right: 1.25rem;
}

.tw-text-left {
  text-align: left;
}

.tw-text-center {
  text-align: center;
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-italic {
  font-style: italic;
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-text-\[\#6E82A9\] {
  --tw-text-opacity: 1;
  color: rgb(110 130 169 / var(--tw-text-opacity));
}

.tw-text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.tw-text-\[\#2879FB\] {
  --tw-text-opacity: 1;
  color: rgb(40 121 251 / var(--tw-text-opacity));
}

.tw-underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.tw-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
