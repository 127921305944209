body.dashboard, body.reports, body.report-details, body.settings {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  background-color: #FAFAFC;

  .content-container {
    background-color: #FAFAFC;

    .content {
      margin: 0px;
      .updated-text {
        color: #6E82A9;
        font-size: 10px;
        float: right;
        padding-top: 10px;
      }
    }
  }

  .widget-title {
    font-size: 12px;
    line-height: 17px;
    color: #2879FB;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;

    &__claim-details {
      margin-bottom: 5px;
    }

    &__claims {
      margin-top: 20px;
    }
  }

  .widget-subtitle {
    font-size: 10px;
    line-height: 15px;
    color: #152744;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;

    &__claim-details {
      margin-bottom: 5px;
    }
  }

  .billing-editor {
    box-sizing: border-box;
    height: 650px;
    border: 1px solid #EBEFF7;
    border-radius: 4px;
    background-color: #FFFFFF;
    margin-bottom: 20px;
    padding: 30px;

    &--column {
      display: flex;
      flex-direction: column;
      padding-right: 50px;
      height: 500px;

      &--adjacent-forms {
        display: flex
      }

      .claim-details--row--item {
        flex: none;
      }
    }
  }

  // TODO this class is reused as a general purpose drawer class. Rename it as such.
  .claim-details {
    margin: 0px;
    padding: 30px;
    overflow-x: hidden;

    &__with-table {
      padding-top: 0px;

      &--title {
        margin-top: 30px;
      }
    }

    &--email-recipient {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 275px;
      align-items: center;
    }

    &__email-settings-drawer {
      &--drawer-title {
        font-size: 30px;
      }

      &--admin-email {
        margin-bottom: 14px;
      }

      &--body {
        display: flex;
        flex-direction: row;
        padding-top: 34px;
        padding-bottom: 120px;

        &--left {
          flex: 1;
          width: 300px;
        }

        &--right {
          flex: 1;
          padding-top: 201px;
          display: flex;
          flex-direction: column;

          &__edit-email {
            padding-top: 169px;
          }
        }
      }

      &--assign-locations-header {
        width: 275px;
        display: flex;
        justify-content: space-between;
      }
    }


    &--unit {
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 11px;
    }

    &--address {
      margin-bottom: 25px;
    }

    &--row {
      display: flex;
      padding-top: 12px;
      padding-bottom: 12px;
      justify-content: space-between;

      &--item {
        font-size: 12px;
        margin-right: 15px;
        min-width: 50px;
        flex: 1;

        &__grow {
          flex-grow: 3;
        }
        &__large {
          font-size: 14px;
        }
      }
    }

    &--status {
      display: flex;
      align-items: center;
      &--basis {
        margin-left: 5px;
        font-size: small;
        color: #E9645F;
        &__success {
          color: #84D43D;
        }
      }
    }

    &--image {
      max-width: 100%;
    }

    &--description {
      flex: 1
    }

    &--ok-button {
      width: 104px;
      height: 36px;
      background-color: #E9645F;
      color: white;
      font-weight: bold;
      border-radius: 4px;
    }

    .name-and-email {
      margin-bottom: 26px;
      margin-top: 26px;
    }

    &--bottom {
      padding-top: 15px;
      height: 120px;
      background-color: white;
      margin-left: -30px;
      margin-bottom: -20px;
      padding-right: 50px;
      border-top: 2px solid #EEEEEE;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      &__email-settings {
        justify-content: space-between;

        &--info {
          width: 275px;
          color: #152744;
          margin-left: 30px;
          margin-top: 10px;
        }

        &--delete {
          margin-right: 65px;
          cursor: pointer;
        }
      }
    }

    &--content {
      overflow: hidden;
      padding-bottom: 160px;
    }
  }  

  .status-pill {
    width: 60px;
    height: 16px;
    border-radius: 3px;
    font-size: 8px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    &__paid {
      background-color: #00B47E21;
      color: #00a976;
    }
    &__in-progress {
      background-color: #EFB80621;
      color: #e6b000;
    }
    &__abandoned {
      background-color: #E7E7E7;
      color: #6E82A9;
    }
    &__denied {
      background-color: #E9645F21;
      color: #e65c57;
    }
    &__pending {
      background-color: #E7E7E7;
      color: #666666;
    }
  }
  .claim-key {
    font-weight: bold;
    font-size: 12px;

    &__large {
      font-size: 14px;
      padding-bottom: 10px
    }
    &__light {
      font-weight: normal;
    }
  }
  .claim-value {
    font-weight: normal;
  }

  .settings--breaker-bar {
    margin-top: 23px;
    height: 29px;
    border-top: 1px solid #E3E9F7;
  }
}

.widget {
  padding: 20px;
  background-color: white;
  border: 1px solid #F5F7FC;
  border-radius: 4px;

  &__claims-table {
    overflow: scroll;
  }

  &__dashboard-widget {
    height: 100%;
  }

  &__long {
    grid-column: col / span 2;
    padding-top: 0px;
    position: sticky;
    top: 0;

    .totals {
      position: sticky;
      top: 0;
      background-color: white;
      padding: 20px;
      margin-bottom: 20px;
      z-index: 100;
  
      .title {
        font-size: 12px;
        line-height: 17px;
        color: #2879FB;
        text-transform: uppercase;
        font-weight: bold;
      }
  
      .body {
        display: flex;
        justify-content: center;
  
        .total + .total {
          border-left: 1px solid #F5F7FC;
        }
  
        .total {
          padding: 10px 30px;
  
          text-align: center;
          .number {
            font-size: 30px;
            font-weight: bold;
          }
          .label {
            font-size: 12px;
            line-height: 17px;
            color: #2879FB;
            font-weight: bold;
          }
        }
      }
    }
  }

  &--zero-state {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -40px;
  }

  .widget-content {
    &--line-chart {
      max-height: 320px;
    }
    .statistics  {
      display: flex;
      margin-bottom: 15px;

      .statistic + .statistic {
        border-left: 1px solid #F5F7FC;
      }

      .statistic {
        flex-grow: 1;
        text-align: center;
        .statistic-value {
          font-size: 30px;
          text-align: center;
          font-weight: bold;
        }
        .statistic-label {
          color: #2879FB;
          font-size: 12px;
          font-weight: bold;
        }

        .statistic-trend {
          margin-top: 10px;
          color: #6E82A9;
          font-size: 12px;
          font-weight: 500;
        }
        .statistic-auto-protect-title {
          height: 1px;
          color: #152744;
          font-family: "Open Sans";
          font-size: 10px;
        }
      }
    }

    .widget-table-row {
      &__summary {
        td{ 
          font-weight: bold;
        }
      }
      td, th {
        // padding: 9px;
        font-family: "Open Sans";
        font-size: 12px;
        font-weight: medium;
      }
      th {
        color: rgba(21,39,68, 0.5)
      }
      td {
        color: #152744
      }
      .progress-bar {
        width: 100px;
        height: 10px;
        border-radius: 5px;
        &__background {
          background-color: #EFEFF8;
        }
      }
    }
  }
}

div.d3-tooltip {
  position: absolute;
  text-align: center;
  padding: .5rem;
  background: #FFFFFF;
  color: #313639;
  border: 1px solid #2779FB;
  border-radius: 8px;
  pointer-events: none;
  font-size: x-small;
}
