.report-details {
  &--options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    &--left {
      display: flex;
      flex: 1;

      &--to {
        color: #152744;
        font-size: 12px;
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
      }
    }

    &--right {
      flex: 2;
    }
  }

  .totals {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    z-index: 100;

    .title {
      font-size: 12px;
      line-height: 17px;
      color: #2879FB;
      text-transform: uppercase;
      font-weight: bold;
    }

    .body {
      display: flex;
      justify-content: center;

      .total + .total {
        border-left: 1px solid #F5F7FC;
      }

      .total {
        padding: 10px 30px;

        text-align: center;
        .number {
          font-size: 30px;
          font-weight: bold;
        }
        .label {
          font-size: 12px;
          line-height: 17px;
          color: #2879FB;
          font-weight: bold;
        }
      }
    }
  }
  .report-title-link {
    display: flex;
    margin-bottom: -20px;
    font-weight: bold;
    .report-title-text {
      font-size: 13px;
      color: #2879FB;
      padding-left: 5px;
    }
  }
}

.return-link {
  font-size: small;
}

