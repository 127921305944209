.settings-content {
  display: flex;
  flex-direction: row;

  &--column {
    width: 328px;
    margin-top: 30px;

    &--title {
      font-family: Open Sans, Arial, sans-serif;
      font-weight: bolder;
      font-size: 14px;
      line-height: 20px;
      color: #152744;
    }
  }

  &--link {
    font-size: 12px;
    color: #2879FB;
    font-weight: bold;
    text-decoration: underline !important;

    &:hover {
      cursor: pointer;
    }
  }
}

.fms-login-fields {
  text-align: center;
  .section {
    .section-label {
      font-weight: bold;
      margin-top: 20px;
    }
  }
}
