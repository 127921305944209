.signin {
  .left {
    min-height: 100vh;
    background-color: white;
  }
  .right {
    margin-top: 110px;
    margin-left: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.signin-options {
  display: flex;
  flex-direction: column;
  align-items: right;
  max-width: 328px;
  &--button {
    width: 100%;
  }
}